<template>
  <teleport to="#menu">
    <div v-if="isVisible" class="menu">
      <div class="mask"></div>
      <div class="content">
        <div class="content__close" @click.prevent="hide">
          <img src="../../assets/close_menu.svg" alt="close_menu" class="content__close__img" />
        </div>
        <div class="content__nav">
          <a
            v-for="item in navList"
            :key="item.id"
            :class="{ content__nav__item: true, 'content__nav__item--active': currentIndex === item.id }"
            :href="item.href"
          >
            <span @click="handleMenuSwitch(item.id)">
              {{ item.name }}
            </span>
          </a>
        </div>
      </div>
    </div>
  </teleport>
</template>
<script lang="ts">
import { defineComponent, ref } from 'vue';
import store from '@/store';
import useDOMCreate from '../../hook/useDomCreate';
import { emitter, useNavbarEffect } from '../GlobalHeder/Header.vue';

const useOperateMuenuEffect = () => {
  const isVisible = ref(true);
  const hide = () => {
    isVisible.value = false;
    emitter.emit('close-menu', true); // 发送close-menu事件
  };
  return {
    isVisible,
    hide,
  };
};

export default defineComponent({
  setup() {
    useDOMCreate('menu');
    const { isVisible, hide } = useOperateMuenuEffect();
    const { navList, handleSwitch, currentIndex } = useNavbarEffect(store);

    const handleMenuSwitch = (id: number) => {
      hide();
      handleSwitch(id);
    };

    return {
      isVisible,
      hide,
      navList,
      handleSwitch,
      currentIndex,
      handleMenuSwitch,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/style/menus.scss';
</style>
