
import { defineComponent } from 'vue';
import GlobalHeader from '../../components/GlobalHeder/Header.vue';
import Footer from '../../components/Footer/Footer.vue';
import Content from '../../components/Content/Content.vue';
import 'normalize.css';
import '../../style/index.scss';

export default defineComponent({
  components: {
    GlobalHeader,
    Footer,
    Content,
  },
});
