<template>
  <div class="dot" v-if="hasDot">
    <div class="dot__item" v-for="item in itemLength" :key="item">
      <a
        href="javascript:;"
        class="dot__item__link"
        :style="{ backgroundColor: item - 1 === currentIndex ? dotBgColor : '#fff' }"
        @click="dotClick(item - 1)"
      ></a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Dot',
  props: {
    itemLength: Number,
    hasDot: Boolean,
    dotBgColor: {
      type: String,
      default: '#ff5000',
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
  },
  setup(_, context) {
    const dotClick = (index: number) => {
      context.emit('dotClick', index);
    };
    return { dotClick };
  },
});
</script>

<style lang="scss" scoped>
.dot {
  height: 1.3rem;
  border-radius: 1rem;
  position: absolute;
  bottom: 5%;
  text-align: center;
  font-size: 0;
  left: 50%;
  margin-left: -7.5%;
  background: rgba(255, 255, 255, 0.3);
  &__item {
    display: inline-block;
    margin: 0.3rem;
    &__link {
      display: block;
      padding-top: 0.8rem;
      width: 0.8rem;
      height: 0;
      border-radius: 50%;
      background: #fff;
    }
  }
}
</style>
