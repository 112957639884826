
import { App, computed, ComputedRef, defineComponent, onUnmounted, reactive, Ref, toRefs } from 'vue';
import mitt from 'mitt';
import { Store, useStore } from 'vuex';
import createMemu from '../createMenu';
import { GlobalDataProps } from '@/store';
// navList数组属性
export interface navListProps {
  name: string;
  id: number;
  href: string;
}

export interface CreateMunuProp {
  mountNode: null | HTMLDivElement;
  menuInstance: null | App<Element>;
}

export const emitter = mitt();

// 创建右侧菜单，并获取返回的dom
const useMenuEffect = () => {
  const data: CreateMunuProp = reactive({
    mountNode: null,
    menuInstance: null,
  });
  const handleClickMenu = () => {
    const { mountNode, menuInstance } = createMemu();
    data.mountNode = mountNode;
    data.menuInstance = menuInstance;
  };
  const { mountNode, menuInstance } = toRefs(data);
  return { handleClickMenu, mountNode, menuInstance };
};

// 导航栏相关
type navbarEffect = (
  store: Store<GlobalDataProps>
) => {
  navList: Array<navListProps>;
  handleSwitch: (index: number) => void;
  currentIndex: ComputedRef<number>;
};

export const useNavbarEffect: navbarEffect = (store: Store<GlobalDataProps>) => {
  const navList: Array<navListProps> = [
    {
      name: 'HOME',
      id: 0,
      href: '#',
    },
    {
      name: 'COLLECTION',
      id: 1,
      href: '#collection',
    },
    {
      name: 'CONTACT',
      id: 2,
      href: '#contact',
    },
  ];

  const currentIndex = computed(() => store.state.menuSelect);
  const handleSwitch = (index: number) => {
    store.commit('changeSelect', index);
  };
  return { navList, handleSwitch, currentIndex };
};

// 关闭右侧Munu
const useCloseMenu = (menuInstance: any, mountNode: Ref<HTMLDivElement | null>) => {
  const callbackFuntion = (flag: boolean) => {
    if (flag && menuInstance.value && mountNode.value) {
      menuInstance.value.unmount(mountNode);
      document.body.removeChild(mountNode.value);
    }
  };
  emitter.on('close-menu', callbackFuntion as any); // emitter监听close-menu事件，并执行回调函数
};

// 根据当前滚动条距离顶部的距离，切换响应的导航栏1颜色
const useScrollTop = (store: Store<GlobalDataProps>) => {
  const handleScrollTop = () => {
    const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    const windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
    const scrollBottom = scrollHeight - windowHeight - scrollTop;

    if (document.body.clientWidth <= 500 && scrollTop > 2400 && scrollTop < 2700) {
      store.commit('changeSelect', 1); // 移动端滚动切换当前导航栏颜色
    } else if (scrollBottom < 10 || (document.body.clientWidth <= 500 && scrollTop > 2700)) {
      store.commit('changeSelect', 2); // 移动端滚动切换当前导航栏颜色
    } else if (document.body.clientWidth <= 500 && scrollTop < 2400) {
      store.commit('changeSelect', 0);
    } else if (document.body.clientWidth <= 1024 && document.body.clientWidth >= 800) {
      if (scrollBottom < 10 || scrollTop > 2000) {
        store.commit('changeSelect', 2);
      } else if (scrollTop >= 1520 && scrollTop < 2000) {
        store.commit('changeSelect', 1);
      } else if (scrollTop < 1300) {
        store.commit('changeSelect', 0);
      }
    } else if (scrollTop > 2100 && scrollTop < 2500) {
      store.commit('changeSelect', 1);
    } else if (scrollBottom < 10 || scrollTop > 2500) {
      store.commit('changeSelect', 2);
    } else if (scrollTop < 1600) {
      store.commit('changeSelect', 0);
    }
  };
  window.addEventListener('scroll', handleScrollTop);
  onUnmounted(() => {
    window.removeEventListener('scroll', handleScrollTop);
  });
};

export default defineComponent({
  name: 'GlobalHeder',
  setup() {
    const store = useStore<GlobalDataProps>();
    const { handleClickMenu, mountNode, menuInstance } = useMenuEffect();
    const { navList, handleSwitch, currentIndex } = useNavbarEffect(store);
    useCloseMenu(menuInstance, mountNode);
    useScrollTop(store);
    return {
      navList,
      handleSwitch,
      currentIndex,
      handleClickMenu,
    };
  },
});
