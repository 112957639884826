<template>
  <div class="content">
    <Title></Title>
    <Introduction></Introduction>
    <Advantage></Advantage>
    <Collection></Collection>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import Title from '../Title/Title.vue';
import Introduction from '../Introduction/Introduction.vue';
import Advantage from '../Advantage/Advantage.vue';
import Collection from '../Collection/Collection.vue';

export default defineComponent({
  name: 'Content',
  components: {
    Title,
    Introduction,
    Advantage,
    Collection,
  },
});
</script>

<style lang="scss" scoped>
@import '@/style/content.scss';
</style>
