
import { defineComponent } from 'vue';

// 处理点击平台跳转
const useAccountEffect = () => {
  const handlerTwitter = () => {
    window.open('https://twitter.com/Glophee'); // twitter跳转
  };
  const handlerFacebook = () => {
    window.open('https://www.facebook.com/Glophee'); // facebook跳转
  };
  return { handlerTwitter, handlerFacebook };
};

// 获取联系账号描述列表
const useConnectEffect = () => {
  const contactList = [
    'Address: 714, building 1, Wuhua Building, Amoy, Fujian',
    'E-mail: contact@glophee.com',
    'Telegram: @Glophee',
    'Facebook: Glophee Flatware',
  ];
  return { contactList };
};

export default defineComponent({
  name: 'Footer',
  setup() {
    const { contactList } = useConnectEffect();
    const { handlerTwitter, handlerFacebook } = useAccountEffect();
    return { contactList, handlerTwitter, handlerFacebook };
  },
});
