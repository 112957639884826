
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Dot',
  props: {
    itemLength: Number,
    hasDot: Boolean,
    dotBgColor: {
      type: String,
      default: '#ff5000',
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
  },
  setup(_, context) {
    const dotClick = (index: number) => {
      context.emit('dotClick', index);
    };
    return { dotClick };
  },
});
