<template>
  <div class="footer">
    <a id="contact"></a>
    <div class="footer__container">
      <div class="footer__container__helper">
        <div class="footer__container__helper__img"></div>
        <div class="footer__container__helper__text">
          Please feel free to contact us if you have any questions, we’ll do our best to offer help<br /><br />
          © 2019-2021, Glophee.com Co.,Ltd
        </div>
      </div>
      <div class="footer__container__connect">
        <div class="footer__container__connect__header">CONTACT US</div>
        <div class="footer__container__connect__body" v-for="item of contactList" :key="item">
          {{ item }}
        </div>
        <div class="footer__container__connect__img">
          <div @click="handlerTwitter">
            <img src="../../assets/twitter.svg" alt="twitter" class="twitter cursor" />
          </div>
          <div @click="handlerFacebook">
            <img src="../../assets/facebook.svg" alt="facebook" class="cursor" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

// 处理点击平台跳转
const useAccountEffect = () => {
  const handlerTwitter = () => {
    window.open('https://twitter.com/Glophee'); // twitter跳转
  };
  const handlerFacebook = () => {
    window.open('https://www.facebook.com/Glophee'); // facebook跳转
  };
  return { handlerTwitter, handlerFacebook };
};

// 获取联系账号描述列表
const useConnectEffect = () => {
  const contactList = [
    'Address: 714, building 1, Wuhua Building, Amoy, Fujian',
    'E-mail: contact@glophee.com',
    'Telegram: @Glophee',
    'Facebook: Glophee Flatware',
  ];
  return { contactList };
};

export default defineComponent({
  name: 'Footer',
  setup() {
    const { contactList } = useConnectEffect();
    const { handlerTwitter, handlerFacebook } = useAccountEffect();
    return { contactList, handlerTwitter, handlerFacebook };
  },
});
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
@import '@/style/mixins.scss';
@import '@/style/footer.scss';
</style>
