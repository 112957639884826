import { createStore } from 'vuex';

export interface GlobalDataProps {
  menuSelect: number;
}
export default createStore<GlobalDataProps>({
  state: { menuSelect: 0 },
  mutations: {
    changeSelect(state, payload: number) {
      state.menuSelect = payload;
    },
  },
  actions: {},
  modules: {},
});
