<template>
  <div class="title">
    <div class="title__desc">
      <div class="title__desc__header">
        <div><img src="../../assets/title_img.svg" alt="title_img" class="title__desc__header__img" /></div>
        <div class="title__desc__header__enjoy">ENJOY</div>
        <div>THE</div>
      </div>
      <div class="title__desc__content">HAPPINESS WITH YOU</div>
    </div>
    <title-button :width="width" :height="height" :isBlack="true" @btn-click="handleBtnClick">
      <span> SHOP NOW </span>
    </title-button>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from 'vue';
import TitleButton from '../TitleButton/TitleButton.vue';
import useTitleSize from '../../hook/useTitleSize';

const useButtonEffect = () => {
  const data = reactive({
    width: '',
    height: '',
  });
  const handleResize = () => {
    if (document.body.clientWidth <= 500) {
      data.width = '16rem';
      data.height = '4rem';
    } else if (document.body.clientWidth > 501 && document.body.clientWidth <= 826) {
      data.width = '20rem';
      data.height = '5rem';
    } else {
      data.width = '25.1rem';
      data.height = '6.1rem';
    }
  };
  const { width, height } = useTitleSize(data, handleResize);
  // 这个是shop now跳转链接
  const handleBtnClick = () => {
    // http协议必须写，网址要写全
    window.open('https://www.amazon.com/dp/B0999N2YVC');
  };
  return {
    width,
    height,
    handleBtnClick,
  };
};

export default defineComponent({
  name: 'Title',
  components: {
    TitleButton,
  },
  setup() {
    const { width, height, handleBtnClick } = useButtonEffect();

    return {
      width,
      height,
      handleBtnClick,
    };
  },
});
</script>

<style lang="scss" scoped>
@import '@/style/content_title.scss';
</style>
