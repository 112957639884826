<template>
  <Home></Home>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import Home from './views/Project/Home.vue';

export default defineComponent({
  components: {
    Home,
  },
});
</script>

<style lang="scss" scoped></style>
