<template>
  <div class="advantage__item">
    <div class="advantage__item__border"></div>
    <div class="advantage__item__title">{{ advantage.title }}</div>
    <div class="advantage__item__desc">
      {{ advantage.desc }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

export interface AdvantageListPrpos {
  title: string;
  desc: string;
  id: number;
}

export default defineComponent({
  name: 'Advantage',
  props: {
    advantage: {
      type: Object as PropType<AdvantageListPrpos>,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/style/advantage_item.scss';
</style>
