
import { defineComponent, reactive } from 'vue';
import useTitleSize from '@/hook/useTitleSize';
import TitleButton from '../TitleButton/TitleButton.vue';

const useButtonEffect = () => {
  const data = reactive({
    width: '',
    height: '',
  });
  const handleResize = () => {
    if (document.body.clientWidth <= 500) {
      data.width = '16rem';
      data.height = '4rem';
    } else {
      data.width = '18.6rem';
      data.height = '5rem';
    }
  };
  const { width, height } = useTitleSize(data, handleResize);

  // FOCUS ON US的跳转链接
  const handleBtnClick = () => {
    // http协议必须写，网址要写全
    window.open('https://www.facebook.com/Glophee');
  };
  return {
    width,
    height,
    handleBtnClick,
  };
};

export default defineComponent({
  name: 'Introduction',
  components: {
    TitleButton,
  },
  setup() {
    const { width, height, handleBtnClick } = useButtonEffect();
    return { width, height, handleBtnClick };
  },
});
