<template>
  <transition>
    <div class="carousel__item" v-show="selfIndex === currentIndex">
      <slot></slot>
    </div>
  </transition>
</template>

<script>
import { defineComponent, getCurrentInstance, reactive, toRefs, watch } from 'vue';

export default defineComponent({
  name: 'CarouselItem',
  setup() {
    const instance = getCurrentInstance();
    const state = reactive({ selfIndex: instance.vnode.key, currentIndex: instance.parent.ctx.currentIndex });
    const { selfIndex, currentIndex } = toRefs(state);
    watch(
      () => instance.parent.ctx.currentIndex,
      value => {
        state.currentIndex = value;
        // eslint-disable-next-line comma-dangle
      }
    );
    return { selfIndex, currentIndex };
  },
});
</script>

<style lang="scss">
@import '@/style/carousel_item.scss';
</style>
