
import { defineComponent } from 'vue';
import Title from '../Title/Title.vue';
import Introduction from '../Introduction/Introduction.vue';
import Advantage from '../Advantage/Advantage.vue';
import Collection from '../Collection/Collection.vue';

export default defineComponent({
  name: 'Content',
  components: {
    Title,
    Introduction,
    Advantage,
    Collection,
  },
});
