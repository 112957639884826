<template>
  <div
    class="title__button"
    :style="{
      backgroundColor: bgColor,
      width: width,
      height: height,
      color: color,
    }"
    @click="handleShop"
  >
    <slot></slot>
    <span class="title__button__right_arrow">
      <img src="../../assets/right_arrow_black.svg" alt="right_arrow" v-if="isBlack" />
      <img src="../../assets/right_arrow_white.svg" alt="right_arrow" v-else />
    </span>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TitleButton',
  props: {
    bgColor: {
      type: String,
      default: '#fff',
    },
    width: {
      type: String,
      required: true,
    },
    height: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '#333',
    },
    isBlack: {
      type: Boolean,
      defalut: true,
    },
  },
  emits: ['btn-click'],
  setup(_, context) {
    const handleShop = () => {
      context.emit('btn-click');
    };
    return { handleShop };
  },
});
</script>

<style lang="scss" scoped>
@import '@/style/title_button.scss';
</style>
