<template>
  <div class="introduction">
    <div class="introduction__container">
      <div class="introduction__container__header">
        <div class="introduction__container__header__left">
          <div class="introduction__container__header__left__title">ABOUT GLOPHEE</div>
          <div class="introduction__container__header__left__desc">
            Elevate your dining experience with a Glophee Flatware Set. We offers a variety of elegant, timeless, modern
            and unique patterns to suit every taste. A perfect design allowing easy cleaning, robust performance, lovely
            quality, simple, elegant. You'll find what you need here, in Glophee!
          </div>
        </div>
        <div class="introduction__container__header__right__wrapper">
          <div class="introduction__container__header__right__wrapper__content"></div>
        </div>
      </div>
      <div class="introduction__container__body">
        <div class="introduction__container__body__left"></div>
        <div class="introduction__container__body__right">
          <div class="introduction__container__body__right__title">PRODUCT ADVANTAGES</div>
          <div class="introduction__container__body__right__desc">
            <span class="bold">High quality:</span> For high-quality titanium-plated silver to enhance the surface
            hardness and avoid scratching the tableware during use.<br />
            <span class="bold">Anti-rust:</span> Strong anti-rust because they are made of 18/0 stainless steel.<br />
            <span class="bold">Food safety: </span>Using high-quality food safety materials, non-toxic and tasteless<br />
            <span class="bold">Durable: </span>The sturdy and durable structure will last for many years.<br />
            <span class="bold">Perfect details:</span> With smooth handles, attractive body curves and perfectly rounded
            spoon bowls. And all edges are carefully polished to ensure your lips are safe.<br />
          </div>
          <title-button
            bgColor="#0E4754"
            :width="width"
            :height="height"
            color="#fff"
            :isBlack="false"
            @btn-click="handleBtnClick"
          >
            <span>FOCUS ON US</span>
          </title-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive } from 'vue';
import useTitleSize from '@/hook/useTitleSize';
import TitleButton from '../TitleButton/TitleButton.vue';

const useButtonEffect = () => {
  const data = reactive({
    width: '',
    height: '',
  });
  const handleResize = () => {
    if (document.body.clientWidth <= 500) {
      data.width = '16rem';
      data.height = '4rem';
    } else {
      data.width = '18.6rem';
      data.height = '5rem';
    }
  };
  const { width, height } = useTitleSize(data, handleResize);

  // FOCUS ON US的跳转链接
  const handleBtnClick = () => {
    // http协议必须写，网址要写全
    window.open('https://www.facebook.com/Glophee');
  };
  return {
    width,
    height,
    handleBtnClick,
  };
};

export default defineComponent({
  name: 'Introduction',
  components: {
    TitleButton,
  },
  setup() {
    const { width, height, handleBtnClick } = useButtonEffect();
    return { width, height, handleBtnClick };
  },
});
</script>

<style lang="scss" scoped>
@import '@/style/content_introduction.scss';
</style>
