
import { defineComponent, ref } from 'vue';
import store from '@/store';
import useDOMCreate from '../../hook/useDomCreate';
import { emitter, useNavbarEffect } from '../GlobalHeder/Header.vue';

const useOperateMuenuEffect = () => {
  const isVisible = ref(true);
  const hide = () => {
    isVisible.value = false;
    emitter.emit('close-menu', true); // 发送close-menu事件
  };
  return {
    isVisible,
    hide,
  };
};

export default defineComponent({
  setup() {
    useDOMCreate('menu');
    const { isVisible, hide } = useOperateMuenuEffect();
    const { navList, handleSwitch, currentIndex } = useNavbarEffect(store);

    const handleMenuSwitch = (id: number) => {
      hide();
      handleSwitch(id);
    };

    return {
      isVisible,
      hide,
      navList,
      handleSwitch,
      currentIndex,
      handleMenuSwitch,
    };
  },
});
