import { Ref, toRefs, onUnmounted, onMounted } from 'vue';

interface TitleSizeProps {
  data: {
    width: string;
    height: string;
  };
  handleResize: () => void;
}

const useTitleSize: (
  data: TitleSizeProps['data'],
  handleResize: TitleSizeProps['handleResize']
) => { width: Ref<string>; height: Ref<string> } = (data, handleResize) => {
  onMounted(() => {
    handleResize();
  });
  window.addEventListener('resize', handleResize);
  onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
  });

  const { width, height } = toRefs(data);
  return {
    width,
    height,
  };
};
export default useTitleSize;
