<template>
  <a id="collection"></a>
  <div class="collection">
    <div class="collection__content">
      <div class="collection__content__title">THE COLLECTION</div>
      <div class="collection__content__body">
        <div class="collection__content__body__left" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
          <Carousel
            :autoplay="true"
            :duration:="3000"
            :initial="0"
            :hasDot="false"
            :hasDirector="true"
            ref="carouselRef"
          >
            <carousel-item v-for="item of carouselList" :key="item.id">
              <img :src="item.img" @click="item.handleClick" />
            </carousel-item>
          </Carousel>
          <div class="collection__content__body__left__set">NEW: Glophee Thon</div>
          <div class="collection__content__body__left__bottom">
            <div class="collection__content__body__left__bottom__dot">
              <div :class="{ collection__content__body__left__bottom__dot__gray: true }" @click="handleSwitch(1)"></div>
              <div
                :class="{ collection__content__body__left__bottom__dot__yellow: true }"
                @click="handleSwitch(2)"
              ></div>
              <div
                :class="{ collection__content__body__left__bottom__dot__black: true }"
                @click="handleSwitch(0)"
              ></div>
            </div>
          </div>
        </div>
        <div class="collection__content__body__right">
          <div class="collection__content__body__right__item" v-for="item of imgList" :key="item">
            <img :src="item.img" class="collection__content__body__right__item__img" @click="item.handleClick" />
            <div class="collection__content__body__right__item__bottom">
              <div class="collection__content__body__right__item__bottom__set">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="collection__content__footer">
        <div class="collection__content__footer__text">view more</div>
        <img
          src="../../assets/right_arrow_black.svg"
          alt="right_arrow_black"
          class="collection__content__footer__arrow"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import mitt from 'mitt';
import { defineComponent } from 'vue';
import Carousel from '../Carousel/Carousel.vue';
import CarouselItem from '../Carousel/CarouselItem/CarouselItem.vue';

export const emitter = mitt();

const useCarouselEffect = () => {
  const carouselList = [
    {
      img: require('../../assets/carousel_item.png'),
      id: 0,
      handleClick: () => {
        window.open('https://www.amazon.com/dp/B096KF3XB5'); // 左边轮播图黑色轮播图
      },
    },
    {
      img: require('../../assets/carousel_item_silver.png'),
      id: 1,
      handleClick: () => {
        window.open('https://www.amazon.com/dp/B096KDSB9Y'); // 左边轮播图灰色轮播图
      },
    },
    {
      img: require('../../assets/carousel_item_gold.png'),
      id: 2,
      handleClick: () => {
        window.open('https://www.amazon.com/dp/B096KGLRBJ'); // 左边轮播图金色轮播图
      },
    },
  ];
  const handleSwitch = (id: number) => {
    emitter.emit('switch-carousel', id);
  };
  const onMouseEnter = () => {
    emitter.emit('stop-carousel');
  };
  const onMouseLeave = () => {
    emitter.emit('start-carousel');
  };
  return { carouselList, handleSwitch, onMouseEnter, onMouseLeave };
};

export default defineComponent({
  name: 'Collection',
  components: { Carousel, CarouselItem },
  setup() {
    const imgList = [
      {
        img: require('../../assets/collection_right_bg_item1.png'),
        id: 0,
        title: 'Glophee Serpent',
        handleClick: () => {
          window.open('https://www.amazon.com/dp/B098M66V71'); // 右边第一张
        },
      },
      {
        img: require('../../assets/collection_right_bg_item2.png'),
        id: 1,
        title: 'Glophee Dauphin',
        handleClick: () => {
          window.open('https://www.amazon.com/dp/B0999JZV14'); // 右边第二张
        },
      },
      {
        img: require('../../assets/collection_right_bg_item3.png'),
        id: 2,
        title: 'Glophee Requin',
        handleClick: () => {
          window.open('https://www.amazon.com/dp/B0999FFJTV'); // 右边第三张
        },
      },
      {
        img: require('../../assets/collection_right_bg_item4.png'),
        id: 3,
        title: 'Glophee Thon',
        handleClick: () => {
          window.open('https://www.amazon.com/dp/B096KGLRBJ'); // 右边第四张
        },
      },
    ];

    const { carouselList, handleSwitch, onMouseEnter, onMouseLeave } = useCarouselEffect();
    return { imgList, carouselList, handleSwitch, onMouseEnter, onMouseLeave };
  },
});
</script>

<style lang="scss" scoped>
@import '@/style/content_collection.scss';
</style>
