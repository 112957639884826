import { App, createApp } from 'vue';
import Menu from './Menu/Menu.vue';

const createMessage: () => {
  mountNode: HTMLDivElement;
  menuInstance: App<Element>;
} = () => {
  const menuInstance = createApp(Menu); // 创建menu
  const mountNode = document.createElement('div');
  document.body.appendChild(mountNode);
  menuInstance.mount(mountNode); // 挂在到mountNode上
  return {
    mountNode,
    menuInstance,
  };
};

export default createMessage;
