
import { defineComponent, onMounted, onUnmounted, reactive, getCurrentInstance, toRefs } from 'vue';
import { emitter } from '../Collection/Collection.vue';
import Dot from './Dot/Dot.vue';

const uselisteneffect = (hanlderSwitch: any, handlerClear: any, autoplay: any) => {
  emitter.on('switch-carousel', hanlderSwitch);
  emitter.on('stop-carousel', handlerClear);
  emitter.on('start-carousel', autoplay);
  onUnmounted(() => {
    emitter.off('switch-carousel', hanlderSwitch);
    emitter.off('stop-carousel', handlerClear);
    emitter.off('start-carousel', autoplay);
  });
};

const useAutoplayEffect = (state: { currentIndex: number; itemLength: number }, props: any) => {
  let t: any = null;
  const instance = getCurrentInstance();

  const setIndex = (dir: string) => {
    switch (dir) {
      case 'next':
        state.currentIndex += 1;
        if (state.currentIndex === state.itemLength) {
          state.currentIndex = 0;
        }
        break;
      case 'prev':
        state.currentIndex -= 1;
        if (state.currentIndex === -1) {
          state.currentIndex = state.itemLength - 1;
        }
        break;
      default:
        break;
    }
  };

  const autoplay = () => {
    if (props.autoplay) {
      t = setInterval(() => {
        setIndex('next');
      }, props.duration);
    }
  };

  onMounted(() => {
    if (instance && instance.slots) {
      if (instance?.slots.default) {
        state.itemLength = instance?.slots.default()[0].children?.length as number;
      }
    }
    autoplay();
  });

  const handlerClear = () => {
    clearInterval(t);
    t = null;
  };

  const onMouseEnter = () => {
    handlerClear();
  };

  const onMouseLeave = () => {
    autoplay();
  };

  onUnmounted(() => {
    handlerClear();
  });

  return { handlerClear, autoplay, onMouseEnter, onMouseLeave };
};

export default defineComponent({
  name: 'Carousel',
  props: {
    autoplay: {
      type: Boolean,
      default: true,
    },
    duration: {
      type: Number,
      default: 3000,
    },
    initial: {
      type: Number,
      default: 0,
    },
    hasDot: {
      type: Boolean,
      default: true,
    },
    hasDirector: {
      type: Boolean,
      default: true,
    },
    dotBgColor: String,
  },
  components: {
    Dot,
  },

  setup(props) {
    const state = reactive({
      currentIndex: props.initial,
      itemLength: 0,
    });
    const dotClick = (index: number) => {
      state.currentIndex = index;
    };

    const hanlderSwitch = (id: number) => {
      state.currentIndex = id;
    };
    const { handlerClear, autoplay, onMouseEnter, onMouseLeave } = useAutoplayEffect(state, props);

    uselisteneffect(hanlderSwitch, handlerClear, autoplay);

    const { currentIndex, itemLength } = toRefs(state);

    return { currentIndex, itemLength, dotClick, onMouseEnter, onMouseLeave };
  },
});
