
import { defineComponent } from 'vue';
import AdvantageItem, { AdvantageListPrpos } from '../AdantageItem/AdantageItem.vue';

export default defineComponent({
  name: 'Advantage',
  components: { AdvantageItem },
  setup() {
    const advantageList: AdvantageListPrpos[] = [
      {
        title: 'DISHWASHER SAFE',
        desc:
          ' If you put the flatware in the dishwasher, keep detergent to a minimum, remove the flatware from the dishwasher after the dry cycle, and then dry items completely with a soft towel. ',
        id: 0,
      },
      {
        title: 'DURABLE ENOUGH',
        desc:
          'Durable Stainless Steel, will not bend; Strong Anti Rust, Smooth mirror polish edge with no rough spot to hurt your mouth. ',
        id: 1,
      },
      {
        title: 'ERGONOMIC DESIGN',
        desc:
          'Well balanced and ergonomically designed. Every detail has been carefully polished, delicately designed and produced.  ',
        id: 2,
      },
      {
        title: 'PREMIUM QUALITY',
        desc:
          'Made of High Quality Stainless Steel, Strong Anti Rust, Lead free and nikle free Healthy for your everyday use.',
        id: 2,
      },
    ];
    return { advantageList };
  },
});
