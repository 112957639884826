
import { defineComponent, PropType } from 'vue';

export interface AdvantageListPrpos {
  title: string;
  desc: string;
  id: number;
}

export default defineComponent({
  name: 'Advantage',
  props: {
    advantage: {
      type: Object as PropType<AdvantageListPrpos>,
      required: true,
    },
  },
});
